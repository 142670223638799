exports.components = {
  "component---src-templates-404-index-ts": () => import("./../../../src/templates/404/index.ts" /* webpackChunkName: "component---src-templates-404-index-ts" */),
  "component---src-templates-campaign-index-ts": () => import("./../../../src/templates/Campaign/index.ts" /* webpackChunkName: "component---src-templates-campaign-index-ts" */),
  "component---src-templates-continent-index-ts": () => import("./../../../src/templates/Continent/index.ts" /* webpackChunkName: "component---src-templates-continent-index-ts" */),
  "component---src-templates-country-index-ts": () => import("./../../../src/templates/Country/index.ts" /* webpackChunkName: "component---src-templates-country-index-ts" */),
  "component---src-templates-home-index-ts": () => import("./../../../src/templates/Home/index.ts" /* webpackChunkName: "component---src-templates-home-index-ts" */),
  "component---src-templates-imprint-index-ts": () => import("./../../../src/templates/Imprint/index.ts" /* webpackChunkName: "component---src-templates-imprint-index-ts" */),
  "component---src-templates-mobile-tc-index-ts": () => import("./../../../src/templates/MobileTC/index.ts" /* webpackChunkName: "component---src-templates-mobile-tc-index-ts" */),
  "component---src-templates-offer-index-ts": () => import("./../../../src/templates/Offer/index.ts" /* webpackChunkName: "component---src-templates-offer-index-ts" */),
  "component---src-templates-pp-declaration-for-job-app-index-ts": () => import("./../../../src/templates/PPDeclarationForJobApp/index.ts" /* webpackChunkName: "component---src-templates-pp-declaration-for-job-app-index-ts" */),
  "component---src-templates-privacy-policy-index-ts": () => import("./../../../src/templates/PrivacyPolicy/index.ts" /* webpackChunkName: "component---src-templates-privacy-policy-index-ts" */),
  "component---src-templates-root-index-ts": () => import("./../../../src/templates/Root/index.ts" /* webpackChunkName: "component---src-templates-root-index-ts" */),
  "component---src-templates-tc-index-ts": () => import("./../../../src/templates/TC/index.ts" /* webpackChunkName: "component---src-templates-tc-index-ts" */),
  "component---src-templates-travel-information-index-ts": () => import("./../../../src/templates/TravelInformation/index.ts" /* webpackChunkName: "component---src-templates-travel-information-index-ts" */),
  "component---src-templates-trust-and-safety-index-ts": () => import("./../../../src/templates/TrustAndSafety/index.ts" /* webpackChunkName: "component---src-templates-trust-and-safety-index-ts" */)
}

